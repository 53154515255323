import React from 'react';
import { Link, navigate } from "@reach/router";
import PropTypes from 'prop-types';

import { ReactComponent as LogoWeb } from "../../images/nakedsword-mrman-logo-black.svg";
import { ReactComponent as LogoMobile } from "../../images/nakedsword-mrman-logo-black.svg";

import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { routes } from "../../services/navigation/navigation.service.routes";
import { env } from '../../env';

import './ApplicationLogo.scss';

const ApplicationLogo = ({ notLink }) => {
  const title = `${env.REACT_APP_SITE_TITLE} Home`;

  const onClick = () => {
    navigate(routes.root);
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
  }

  const Elem = notLink ? 'span' : Link;
  const props = {
    className: 'ApplicationLogo',
    title: title
  };

  if (!notLink) {
    props.onClick = onClick;
    props.to = routes.root;
  }

  return (
    <Elem {...props}>
      <LogoWeb />
      <LogoMobile />
    </Elem>
  );
}

ApplicationLogo.propTypes = {
  notLink: PropTypes.bool
};

export default ApplicationLogo;

